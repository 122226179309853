import React from "react";
import "./Footer.css";
import logo from "../../images/logo.svg";
import devLogo from "../../images/dev-logo.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-overlay"></div>
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A425fc73434034bb92ecaba653f476c7fa9aaf6885dfa010573135acc67b67089&amp;source=constructor"
        height="654"
        frameBorder="0"
        title="карта"
        className="footer__layout"
      ></iframe>
      <ul className="footer__contacts">
        <h2 className="footer__contacts-title">Контакты</h2>
        <li className="footer__contacts-item">
          <h3 className="contacts-title">Приемная</h3>
          <p className="contacts-item">+7 (7172) 72 50 55</p>
        </li>
        <li className="footer__contacts-item">
          <h3 className="contacts-title">Рабочая почта</h3>
          <p className="contacts-item">info@aqmolpro.kz</p>
        </li>
        <li className="footer__contacts-item">
          <h3 className="contacts-title">Адрес</h3>
          <p className="contacts-item">
            010000, РК г.Астана, район Есиль, ул.Сарайшык, здание 5/4, н.п.1
          </p>
        </li>
      </ul>
      <div className="footer__copyrights">
        <img src={logo} className="footer__logo" alt="Логотип"></img>
        <p className="footer__text">
          ГСЛ № 20010801 от 27.07.2020г. - I категория
        </p>
        <p className="footer__text">© {currentYear}</p>
      </div>
    </footer>
  );
};

export default Footer;
