import "./App.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutProject from "../AboutProject/AboutProject";
import Projects from "../Projects/Projects";
import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Main from "../Main/Main";
import AboutUs from "../AboutUs/AboutUs";
import NotFound from "../NotFound/NotFound";
import "./transitions.css";
import projects from "../../arrays/projects1.json";

function App() {
  const location = useLocation();

  const isNotFoundPage = location.pathname === "/404";

  return (
    <div className="App">
      <Header />
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={600}>
          <Routes location={location}>
            <Route path="/" element={<Main />} />
            <Route path="/about-company" element={<AboutUs />} />
            <Route path="/projects/:filter" element={<Projects />} />
            <Route
              path="/projects"
              element={<Navigate to="/projects/all" replace />}
            />
            {projects.projects.map((project) => (
              <Route
                key={project.id}
                path={`/projects/${project.id}`}
                element={<AboutProject project={project} />}
              />
            ))}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      {!isNotFoundPage && <Footer />}
    </div>
  );
}

export default App;
