import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel } from "swiper/modules";
import "./Slider.css";

function Slider({ photos, title }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupIndex, setPopupIndex] = useState(0);

  const totalSlides = photos.length;

  const prevSlide = useCallback(() => {
    if (!showPopup) {
      setCurrentIndex((currentIndex - 1 + totalSlides) % totalSlides);
    } else {
      const newIndex = (popupIndex - 1 + totalSlides) % totalSlides;
      setPopupIndex(newIndex);
    }
  }, [currentIndex, popupIndex, showPopup, totalSlides]);

  const nextSlide = useCallback(() => {
    if (!showPopup) {
      setCurrentIndex((currentIndex + 1) % totalSlides);
    } else {
      const newIndex = (popupIndex + 1) % totalSlides;
      setPopupIndex(newIndex);
    }
  }, [currentIndex, popupIndex, showPopup, totalSlides]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowPopup(false);
      } else if (event.key === "ArrowLeft") {
        prevSlide();
      } else if (event.key === "ArrowRight") {
        nextSlide();
      }
    };

    if (showPopup) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showPopup, prevSlide, nextSlide]);

  const openPopup = (index) => {
    setPopupIndex(index);
    setShowPopup(true);
  };

  const handleOverlayClose = (evt) =>
    evt.target === evt.currentTarget && setShowPopup(false);

  return (
    <div className="gallery-slider">
      <h2 className="gallery-slider__title">{title}</h2>
      <div className="controls">
        <div className="arrows">
          <button className="button_prev" onClick={prevSlide}></button>
          <button className="button_next" onClick={nextSlide}></button>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Mousewheel]}
        spaceBetween={30}
        slidesPerView={"auto"}
        navigation={{
          nextEl: ".button_next",
          prevEl: ".button_prev",
        }}
        className="gallery-slider__gallery"
        mousewheel={true}
        onSwiper={(swiper) => {
          swiper.update();
        }}
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index} className="gallery-slider__items">
            <img
              className="slider-img"
              src={photo}
              alt={`Slide ${index + 1}`}
              onClick={() => openPopup(index)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {showPopup && (
        <div className="popup-container" onClick={handleOverlayClose}>
          <button
            className="close-popup-btn"
            onClick={() => setShowPopup(false)}
          >
            X
          </button>
          <div className="arrow" onClick={prevSlide}>
            ❮
          </div>
          <img
            className="popup-image"
            src={photos[popupIndex]}
            alt={`Popup Slide ${popupIndex + 1}`}
          />
          <div className="arrow" onClick={nextSlide}>
            ❯
          </div>
        </div>
      )}
    </div>
  );
}

export default Slider;
