// import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AboutCompany.css";
import logo from "../../images/logo.svg";
// import ContactModal from "../ContactModal/ContactModal";

const AboutCompany = ({
  backgroundColor,
  textColor,
  border,
  showButtons = true,
  addTextShadow = false,
}) => {
  const handleScrollToTop = () => {
    // Прокручиваем страницу вверх
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <section
      className="about-company"
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      <h2
        className="about-company__title"
        style={{
          textShadow: addTextShadow ? "2px 2px 4px rgba(0, 0, 0, 0.7)" : "none",
        }}
      >
        О компании
      </h2>
      <p
        className="about-company__text"
        style={{
          textShadow: addTextShadow ? "2px 2px 4px rgba(0, 0, 0, 0.7)" : "none",
        }}
      >
        Мы специализируемся на полном цикле работ в области
        архитектурно-строительного проектирования
      </p>
      <div
        className="about-company__logo"
        style={{ borderRight: border, borderTop: border }}
      >
        <img src={logo} alt="Логотип" className="about-logo"></img>
      </div>
      <ul
        className="about-company__points"
        style={{
          borderTop: border,
          textShadow: addTextShadow ? "2px 2px 4px rgba(0, 0, 0, 0.7)" : "none",
        }}
      >
        Члены нашей команды обладают многолетним опытом работы в строительной
        сфере, прошли обучение и сертификацию в области применения
        BIM-технологий в проектировании.
        <li className="about-company__point">
          Постоянно развиваемся, чтобы быть конкурентно способными
        </li>
        <li className="about-company__point">
          Работаем с разной категорией клиентов
        </li>
        <li className="about-company__point">
          Ведем авторский надзор и техническое сопровождение объекта до ввода в
          эксплуатацию
        </li>
        <li className="about-company__point">
          Учитываем пожелания клиента, консультируем в выборе правильных
          архитектурных, технических, инженерных решений
        </li>
        <li className="about-company__point">
          Рассчитываем бюджет объекта в период строительства и затраты в период
          эксплуатации, что позволяет легко и быстро строить Заказчику и
          комфортно жить людям
        </li>
      </ul>
      {showButtons && (
        <div className="buttons">
          <Link
            to="/about-company"
            className="about-company__button-more"
            onClick={handleScrollToTop}
          >
            Подробнее
          </Link>
          {/* <button className="about-company__button-ask" onClick={openModal}>
            Задать вопрос
          </button>{" "}
          {isModalOpen && (
            <ContactModal isOpen={isModalOpen} onClose={closeModal} />
          )}{" "} */}
        </div>
      )}
    </section>
  );
};

export default AboutCompany;
