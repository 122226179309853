import React from "react";
import "./AboutUs.css";
import Slider from "../Slider/Slider";
import AboutCompany from "../AboutCompany/AboutCompany";
import Leadership from "../Leadership/Leadership";

const companyPhotos = [
  "../images/AboutCompany/1.JPG",
  "../images/AboutCompany/2.jpg",
  "../images/AboutCompany/3.jpg",
  "../images/AboutCompany/4.jpg",
  "../images/AboutCompany/5.jpg",
  "../images/AboutCompany/6.jpg",
  "../images/AboutCompany/7.jpg",
  "../images/AboutCompany/8.jpg",
  "../images/AboutCompany/9.jpg",
  "../images/AboutCompany/10.jpg",
  "../images/AboutCompany/11.jpg",
  "../images/AboutCompany/12.jpg",
  "../images/AboutCompany/13.jpg",
  "../images/AboutCompany/14.jpg",
];

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const AboutUs = () => {
  const shuffledPhotos = shuffleArray([...companyPhotos]);

  return (
    <section className="about-us">
      <img
        className="about-us__image"
        src={"../images/AboutCompany/15.jpg"}
        alt="Команда AQMOL-project"
      ></img>
      <AboutCompany
        backgroundColor="#FFFFFF"
        textColor="#1A1A18"
        showButtons={false}
        border="1px solid #E0E0E0"
        addTextShadow={false}
      />
      <Slider photos={shuffledPhotos} title="Галерея" />
      <Leadership />
    </section>
  );
};

export default AboutUs;
