import React, { useState, useEffect } from "react";
import "./ProjectsSlider.css";
import { Link } from "react-router-dom";
import AboutProject from "../AboutProject/AboutProject";
import projectsData from "../../arrays/projects1.json";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";

function ProjectsSlider({ title }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Перемешиваем массив проектов
    const shuffledProjects = [...projectsData.projects].sort(
      () => Math.random() - 0.5
    );
    setProjects(shuffledProjects);
  }, []);

  return (
    <div className="projects-slider">
      <h2 className="projects-slider__title">
        <Link
          to="/projects"
          className="projects-slider__title-link"
          onClick={() => window.scrollTo(0, 0)}
        >
          <span className="link-title">{title}</span>{" "}
        </Link>
      </h2>
      <div className="controls">
        <Link
          className="more"
          to="/projects"
          onClick={() => window.scrollTo(0, 0)}
        >
          Смотреть все
        </Link>
        <div className="arrows">
          <button className="prev"></button>
          <button className="next"></button>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Mousewheel]}
        spaceBetween={30}
        slidesPerView={"auto"}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        className="projects-slider__gallery"
        loop={true}
        mousewheel={true}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id} className="project-slider__items">
            <Link
              to={`/projects/${project.id}`}
              element={<AboutProject />}
              onClick={() => window.scrollTo(0, 0)}
              className="projects-slider__item swiper-slide"
            >
              <div className="project-overlay"></div>
              <img
                className="project-image"
                src={project.image}
                alt={project.name}
              ></img>
              <p className="project-caption">{project.location}</p>
              <h2 className="project-name">{project.name}</h2>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ProjectsSlider;
