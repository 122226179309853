import React from "react";
import "./FilterCheckbox.css";

const FilterCheckbox = ({ filter, onChange }) => {
  const handleCheckboxChange = (newFilter) => {
    onChange(newFilter);
  };

  return (
    <section className="checkbox">
      <span
        className={`checkbox__text ${
          filter === "all" ? "checkbox__text--active" : ""
        }`}
        onClick={() => handleCheckboxChange("all")}
      >
        Все
      </span>
      <span
        className={`checkbox__text ${
          filter === "public" ? "checkbox__text--active" : ""
        }`}
        onClick={() => handleCheckboxChange("public")}
      >
        Общественные
      </span>
      <span
        className={`checkbox__text ${
          filter === "residential" ? "checkbox__text--active" : ""
        }`}
        onClick={() => handleCheckboxChange("residential")}
      >
        Жилые
      </span>
    </section>
  );
};

export default FilterCheckbox;
