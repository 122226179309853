import React, { useState, useEffect } from "react";
import "./Header.css";
import HeaderNav from "../HeaderNav/HeaderNav";
import logo from "../../images/logo.svg";
import ContactModal from "../ContactModal/ContactModal";
import { Link } from "react-router-dom";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const iconMenu = document.querySelector(".menu__icon");
    const navBody = document.querySelector(".nav__body");
    const navLinks = document.querySelectorAll(".nav__link"); // Получаем все ссылки в меню навигации

    const handleClick = (e) => {
      document.body.classList.toggle("_lock");
      iconMenu.classList.toggle("_active");
      navBody.classList.toggle("_active");
    };

    const handleLinkClick = () => {
      // При клике на ссылку скрываем меню навигации
      document.body.classList.remove("_lock");
      iconMenu.classList.remove("_active");
      navBody.classList.remove("_active");
    };

    if (iconMenu) {
      iconMenu.addEventListener("click", handleClick);
    }

    // Добавляем обработчик клика для каждой ссылки в меню навигации
    navLinks.forEach((link) => {
      link.addEventListener("click", handleLinkClick);
    });

    return () => {
      if (iconMenu) {
        iconMenu.removeEventListener("click", handleClick);
      }
      // Удаляем обработчик клика для каждой ссылки при демонтировании компонента
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleLinkClick);
      });
    };
  }, []);

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logo ">
          <Link to="/">
            <img src={logo} className="header__logo-img" alt="logo"></img>
          </Link>{" "}
          <p className="header__logo-quote">С нами легко строить</p>
        </div>
        <HeaderNav></HeaderNav>
        {/* <button className="header__button" onClick={openModal}>
          Заказать звонок
        </button> */}
        {isModalOpen && (
          <ContactModal isOpen={isModalOpen} onClose={closeModal} />
        )}
        <div className="header__menu menu">
          <div className="menu__icon">
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
