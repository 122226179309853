import React from "react";
import "./Main.css";
import Promo from "../Promo/Promo";
// import Services from "../Services/Services";
import AboutCompany from "../AboutCompany/AboutCompany";
import ProjectsSlider from "../ProjectsSlider/ProjectsSlider";

const Main = () => {
  return (
    <main className="main">
      <Promo></Promo>
      {/* <Services></Services> */}
      <AboutCompany textColor="#fff" addTextShadow={true}></AboutCompany>
      <ProjectsSlider title="Проекты"></ProjectsSlider>
    </main>
  );
};

export default Main;
