import React from "react";
import "./HeaderNav.css";
import { Link, useLocation } from "react-router-dom";

const HeaderNav = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  // const handleServicesClick = (event) => {
  //   event.preventDefault();
  //   navigate("/");
  //   setTimeout(() => {
  //     const servicesElement = document.getElementById("services");
  //     if (servicesElement) {
  //       const headerHeight = document.querySelector("header").offsetHeight; // Получаем высоту вашего фиксированного заголовка
  //       const elementPosition = servicesElement.getBoundingClientRect().top; // Получаем позицию верхнего края секции относительно видимой области окна
  //       window.scrollTo({
  //         top: elementPosition - headerHeight, // Прокручиваем на позицию с учетом высоты заголовка
  //         behavior: "smooth",
  //       });
  //     }
  //   }, 100);
  // };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className="nav__body">
      <Link
        className={`nav__link ${location.pathname === "/" && "active"}`}
        to="/"
        onClick={handleScrollToTop}
      >
        ГЛАВНАЯ
      </Link>
      {/* <Link
        className={`nav__link ${location.pathname === "#services" && "active"}`}
        to="/#services"
        onClick={handleServicesClick}
      >
        УСЛУГИ
      </Link> */}
      <Link
        className={`nav__link ${
          location.pathname === "/about-company" && "active"
        }`}
        to="/about-company"
        onClick={handleScrollToTop}
      >
        О КОМПАНИИ
      </Link>
      <Link
        className={`nav__link ${location.pathname === "/projects" && "active"}`}
        to="/projects"
        onClick={handleScrollToTop}
      >
        ПРОЕКТЫ
      </Link>
      <Link
        className="links__image-instagram link"
        alt="instagram"
        to="https://www.instagram.com/aqmol_project/"
        target="_blank"
      ></Link>
    </nav>
  );
};

export default HeaderNav;
