import React, { useState, useEffect, useMemo } from "react";
import "./Leadership.css";
import Popup from "../Popup/Popup";

import Director from "../../images/Бекбауов С.К..jpeg";
import Director2 from "../../images/Добролюбова Н..jpeg";
// import Leadership1 from "../../images/Leadership1.png";
// import Leadership2 from "../../images/Leadership2.png";
// import Leadership3 from "../../images/Leadership3.png";
// import Leadership4 from "../../images/Leadership4.png";
// import Project5 from "../../images/Sensata.png";
// import Project6 from "../../images/promoImage.png";

const Leadership = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [leaderPerRow, setLeaderPerRow] = useState(4);

  const leaders = useMemo(() => [
    {
      image: Director,
      name: "Серикпай Бекбауов",
      position: "Директор ТОО «AQMOL-project»",
      description: `Родился 07 ноября 1966 года в  с. Никольское, Макинский район, Целиноградская область
      Окончил Целиноградский инженерно-строительный институт.
      В 1989 году начинал свою трудовую деятельность со слесаря автоцентра «КАМАЗ». В 1991 году занимал руководящую должность в ТОО «Магнит» и ТОО «Акмол-Бизнес».
      С 2001года руководит проектной организацией «AQMOL-project» и по настоящее время.
      Женат, воспитывает троих детей.
      Увлечения: 
      Работа  - мое хобби
      `,
      points: [
        `Государственная медаль «Қазақстан Республикасының тәуелсіздігіне 20 жыл»`,
        `Почетная грамота от Министерства индустрии и инфраструктурного развития за
        Почетный знак «Даңқты құрылысшы» от Ассоциации строителей Казахстана
        `,
        `Почетный знак "Құрметті жобалаушы" от СРО Республиканский союз проектировщиков`,
      ],
    },
    {
      image: Director2,
      name: "Надежда Добролюбова",
      position:
        "Заместитель директора по техническим вопросам/Главный архитектор проектов",
      description: `Родилась 28 января в 1967 году в Россииской Федерации, Костромской области.
      Окончила в 1989 году факультет архитекутры Целиноградского сельскохозяйственного института.
      С  1989  по 1997 годы работала архитектором в отделе комплексного проектироования п.т.г. Кульсары Атырауской области, в  ГГПИ «Казгорстройпроект» в г. Алматы. В разные годы была архитектором в проектных организациях.  
      С 2003 года работает в  ТОО «AQMOL-project», курирует производсвенно-техническую деятельность компании.
      Замужем, имеет двух детей.
      
      Надежда Борисовна является Членом Союза градостроителей Казахстана. 
      
      Увлечения: Изучение архитектуры разных периодов
      `,
      points: [`Государственная медаль «Құрметті сәулетші»`],
    },
  ]);

  useEffect(() => {
    const handleResize = () => {
      const leaderCount = leaders.length;
      if (window.innerWidth <= 768) {
        setLeaderPerRow(Math.min(2, leaderCount));
      } else if (window.innerWidth <= 1090) {
        setLeaderPerRow(Math.min(3, leaderCount));
      } else {
        setLeaderPerRow(Math.min(4, leaderCount));
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [leaders]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? leaders.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === leaders.length - 1 ? 0 : prevIndex + 1
    );
  };

  const cyclicLeaders = leaders.concat(leaders.slice(0, 4));

  const openPopup = (leader) => {
    setSelectedLeader(leader);
    setPopupOpen(true);
  };

  return (
    <section className="leadership">
      <h2 className="leadership__title">Руководство компании</h2>
      <div className="leadership-controls">
        {leaders.length >= 5 && (
          <>
            <button className="prev" onClick={handlePrev}></button>
            <button className="next" onClick={handleNext}></button>
          </>
        )}
      </div>
      <ul className="leadership__gallery">
        {cyclicLeaders
          .slice(currentIndex, currentIndex + leaderPerRow)
          .map((leader, index) => (
            <li
              key={index}
              className="leadership__item"
              onClick={() => openPopup(leader)}
            >
              <img
                className="leader-image"
                src={leader.image}
                alt={`leader${index + 1}`}
              ></img>
              <h3 className="leader-name">{leader.name}</h3>
              <p className="leader-caption">{leader.position}</p>
            </li>
          ))}
      </ul>
      <Popup
        isOpen={popupOpen}
        onClose={() => setPopupOpen(false)}
        content={
          selectedLeader && (
            <div className="leadership-popup">
              <div className="leader-main">
                <img
                  className="leader-image"
                  src={selectedLeader.image}
                  alt="leader"
                />
                <h3 className="leader-name">{selectedLeader.name}</h3>
                <p className="leader-position">{selectedLeader.position}</p>
              </div>
              <div className="leader-description">
                <p className="leader-captions">{selectedLeader.description}</p>
                {selectedLeader.points &&
                Array.isArray(selectedLeader.points) ? (
                  <ul className="leader-text">
                    {selectedLeader.points.map((point, index) => (
                      <li key={index} className="text-point">
                        {point}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          )
        }
      />
    </section>
  );
};

export default Leadership;
