import React, { useEffect } from "react";
import "./Popup.css";

const Popup = ({ isOpen, onClose, content }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Запрещаем прокрутку body
    } else {
      document.body.style.overflow = "auto"; // Разрешаем прокрутку body
    }

    return () => {
      document.body.style.overflow = "auto"; // Устанавливаем обратно прокрутку body при размонтировании компонента
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <button className="close" onClick={onClose}></button>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        {content}
      </div>
    </div>
  );
};

export default Popup;
