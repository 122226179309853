import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ProjectsCard.css";

const ProjectsCard = ({ project }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo(0, 0); // Прокрутка страницы к верху
    navigate(`/projects/${project.id}`); // Переход на страницу проекта
  };

  return (
    <Link
      to={`/projects/${project.id}`}
      className="projects__card"
      onClick={handleClick}
    >
      <div className="project__card-overlay">
        <h2 className="project__card-title">{project.name}</h2>
        <p className="project__card-location">{project.location}</p>
      </div>
      <img
        className="project__card-image"
        src={project.image}
        alt={project.name}
      />
    </Link>
  );
};

export default ProjectsCard;
