import React from "react";
import "./Promo.css";
import PromoLinks from "../PromoLinks/PromoLinks";

const Promo = () => {
  return (
    <section className="promo">
      <h1 className="promo__title">
        СОЗДАЕМ УМНЫЕ ПРОЕКТЫ<br></br> ДЛЯ КОМФОРТА ЛЮДЕЙ
      </h1>
      <ul className="promo__summary">
        <li className="promo__summary-point">
          <h2 className="promo__summary-title">2001</h2>
          <h3 className="promo__summary-text">Год образования</h3>
        </li>
        <li className="promo__summary-point">
          <h2 className="promo__summary-title">500+</h2>
          <h3 className="promo__summary-text">Выполненных объектов</h3>
        </li>
        <li className="promo__summary-point">
          <h2 className="promo__summary-title">1M+</h2>
          <h3 className="promo__summary-text">Общая площадь</h3>
        </li>
      </ul>
      {/* <PromoLinks className="promo__links"></PromoLinks> */}
    </section>
  );
};

export default Promo;
