import React from "react";
import "./AboutProject.css";
import Slider from "../Slider/Slider";
import ProjectsSlider from "../ProjectsSlider/ProjectsSlider";

const AboutProject = ({ project }) => {
  return (
    <section className="project">
      <img
        className="project__photo"
        alt={project.name}
        src={project.image}
      ></img>
      <div className="project__points">
        <h2 className="project__title">{project.name}</h2>
        <ul className="project__list">
          <li className="project__list-points">
            <h3 className="project__list-point">Местоположение: </h3>
            <p className="project__list-text">{project.location}</p>
          </li>
          <li className="project__list-points">
            <h3 className="project__list-point">Год проектирования:</h3>
            <p className="project__list-text">{project.year}</p>
          </li>
          <li className="project__list-points">
            <h3 className="project__list-point">Площадь застройки: </h3>
            <p className="project__list-text">{project.area}</p>
          </li>
          <li className="project__list-points">
            <h3 className="project__list-point">Стадия:</h3>
            <p className="project__list-text">{project.stage}</p>
          </li>
        </ul>
        <div className="project__description">
          {" "}
          <p className="project__description-point">{project.description}</p>
          {/* {project.description.map((desc, index) => (
            <p key={index} className="project__description-point">
              {desc}
            </p>
          ))} */}
        </div>
      </div>
      <Slider photos={project.photos} title="Галерея"></Slider>
      <ProjectsSlider title="Другие проекты"></ProjectsSlider>
    </section>
  );
};

export default AboutProject;
