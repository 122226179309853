import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Projects.css";
import FilterCheckbox from "../FilterCheckbox/FilterCheckbox";
import ProjectsCardList from "../ProjectsCardList/ProjectsCardList";
import ButtonMore from "../ButtonMore/ButtonMore";
import ProjectsCard from "../ProjectsCard/ProjectsCard";
import projectsData from "../../arrays/projects1.json";
const projects = projectsData.projects;

const Projects = () => {
  const navigate = useNavigate();
  const { filter: filterParam } = useParams();
  const filter = filterParam || "all";

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [totalProjectsCount, setTotalProjectsCount] = useState(0);
  const projectsPerPage = 16;

  useEffect(() => {
    filterProjects(filter);
  }, [filter]);

  const filterProjects = (filterParam) => {
    let filtered;
    switch (filterParam) {
      case "residential":
        filtered = projects.filter(
          (project) => project.type === "Жилое здание"
        );
        break;
      case "public":
        filtered = projects.filter(
          (project) => project.type === "Общественное здание"
        );
        break;
      default:
        filtered = projects;
    }

    // Сортируем от большего года к меньшему
    filtered.sort((a, b) => b.year - a.year);

    const initialProjects = filtered.slice(0, projectsPerPage);
    setFilteredProjects(initialProjects);
    setTotalProjectsCount(filtered.length);
    setCurrentProjectIndex(projectsPerPage);
    updateButtonState(projectsPerPage, filtered.length);
  };

  const updateButtonState = (currentIndex, totalCount) => {
    setButtonDisabled(currentIndex >= totalCount);
  };

  const loadMoreProjects = (event) => {
    event.preventDefault(); // Предотвращаем стандартное поведение
    const newProjectsEndIndex = currentProjectIndex + projectsPerPage;
    let newProjects;
    switch (filter) {
      case "residential":
        newProjects = projects.filter(
          (project) => project.type === "Жилое здание"
        );
        break;
      case "public":
        newProjects = projects.filter(
          (project) => project.type === "Общественное здание"
        );
        break;
      default:
        newProjects = projects;
    }

    // Сортируем от большего года к меньшему
    newProjects.sort((a, b) => b.year - a.year);

    const filteredNewProjects = newProjects.slice(
      currentProjectIndex,
      newProjectsEndIndex
    );

    setFilteredProjects((prevFilteredProjects) => [
      ...prevFilteredProjects,
      ...filteredNewProjects,
    ]);

    setCurrentProjectIndex(newProjectsEndIndex);
    updateButtonState(newProjectsEndIndex, totalProjectsCount);
  };

  const handleFilterChange = (newFilter) => {
    navigate(`/projects/${newFilter}`);
  };

  return (
    <section className="projects">
      <div className="projects-header">
        <h2 className="projects__title">Проекты</h2>
        <FilterCheckbox filter={filter} onChange={handleFilterChange} />
      </div>
      <ProjectsCardList>
        {filteredProjects.map((project) => (
          <ProjectsCard key={project.id + project.type} project={project} />
        ))}
      </ProjectsCardList>
      <ButtonMore
        loadMore={loadMoreProjects}
        buttonDisabled={buttonDisabled}
        buttonClass={`button-more ${
          buttonDisabled ? "button-more--inactive" : ""
        }`}
      />
    </section>
  );
};

export default Projects;
