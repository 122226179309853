import React from "react";
import "./ButtonMore.css";

const ButtonMore = ({ loadMore, buttonDisabled, buttonClass }) => {
  return (
    <button
      className={buttonClass}
      onClick={loadMore}
      disabled={buttonDisabled}
    >
      Ещё
    </button>
  );
};

export default ButtonMore;
